.modal.show .modal-dialog.preview {
    // transform: scale(0.8);
    // -ms-transform: scale(0.8);
    // -webkit-transform: scale(0.8);
    // transition: none;
}
.previewOnly {
    max-height: 700px;
    div[cpid] {
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        margin-left: 50%;
        zoom: 0.9;
        max-height: 700px;
        overflow-y: scroll;
        .assetseditor,
        .panelHeader,
        .inline-textEditor,
        .carousel-inner {
            pointer-events: none;
        }
    }
}
