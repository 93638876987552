//Custom css
// link fund modal width
.modal-dialog {
    &.link-fund-modal {
        max-width: 1024px;
        .link-fund-arrow {
            max-width: 280px;
            margin: auto;
        }
    }
}

//Privacy & Security
.Privacy-Security {
    .modal-header {
        .btn-close {
            display: none;
        }
    }
    .modal-footer {
        button {
            &:first-child {
                display: none;
            }
        }
    }
}
.footer-text {
    span {
        font-size: 12px;
    }
}

// Modal Variant icon
.modal-header {
    border-bottom: 0;
    &.modal-variant {
        .btn-close {
            background: transparent
                url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
                center/1em auto no-repeat;
        }
    }
}

// Passwords Must alert section
.custom-alert {
    p {
        margin-bottom: 8px;
    }
    ul {
        padding-left: 13px;
        margin-bottom: 0;
    }
}

// Connection Error
.connection-error {
    .modal-footer {
        display: none !important;
    }
}

// Modal border custom
.custom-modal-border {
    padding: 0;
    p {
        padding: 0.5rem 1rem 0.5rem 1rem;
        margin-bottom: 0;
    }
    .custom-padding {
        padding: 1rem;
        p {
            padding: 0;
        }
    }
}
/*
* All user select for bulk delte
* Add css for hide primary button
*/
.all-user-delete-modal .btn-primary {
    display: none;
}

/*
* Add css for Select Default Header Image Modal 
* Add outline on selected image 
*/
.default-header-image-modal {
    .inActive {
        border: 4px solid transparent;
    }
    .active {
        border: 4px solid #00afaa;
        outline: none;
    }
}

//BTD greeting msg max character position
.default-greeting-message {
    .text-limit {
        background: 0 0;
        border: 0;
        box-shadow: none;
        float: none;
        height: auto;
        margin: 0;
        max-width: none;
        outline: 0;
        padding: 0;
        position: static;
        width: auto;
    }
}
//BTD default image modal UI
.default-header-image-modal {
    .header-image-wrap {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        img {
            max-width: 100%;
            max-height: 150px;
            object-fit: contain;
        }
    }
}

.modal-info-icon {
    color: #ffffff !important;
}

/*
 * select template with preview modal
 */
.select-template-with-preview-modal {
    .card {
        height: 330px;
    }
    img {
        box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 40%);
    }
}

/**
 * Manage download modal plus icon width adjust
 */
.Manage-downloads-modal {
    .add-fiscal-year-btn {
        span {
            font-size: 20px;
            padding: 3px 14px;
            display: block;
        }
    }
}

/**
 * custom css for dripicons icons 
 * Need to add custom-dripicons class on i tag
 */
.custom-dripicons {
    [class^='dripicons-']:before,
    [class*=' dripicons-']:before {
        vertical-align: initial;
        line-height: inherit;
        font-size: inherit;
    }
}

//Warning block setup
.warning-wrapper {
    padding-bottom: 10px;
}
.custom-warning {
    .warning-wrapper {
        padding-top: 10px;
        padding-bottom: 0;
    }
}

/*custom-card-panel
**fixed height & scroll
*/
.select-content-panel,
.replace-asset {
    .custom-card-height {
        height: 400px;
        max-height: 400px;
        overflow-y: scroll;
    }
}

/**
 * Asset Upload modal - max height for upload file modal 
 */
.dropzone-previews {
    max-height: 400px;
    overflow: auto;
    .avatar-sm {
        object-fit: contain;
    }
    .hover-danger {
        &:hover {
            color: $danger !important;
        }
    }
    .progress {
        margin: 0;
        padding: 0;
        height: 12px;
    }
}

//Asset preview modal
.asset-preview {
    .preview-box {
        max-width: 720px;
        width: auto;
        height: auto;
        img,
        iframe {
            width: inherit;
            max-width: inherit;
            height: inherit;
        }
        .video-block {
            width: 500px !important;
            height: 300px !important;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
}
.no-preview {
    i {
        font-size: 100px;
        opacity: 0.5;
    }
}
.linked-record,
.duplicate-site {
    .custom-listgroup {
        position: absolute;
        width: 79.5%;
        z-index: 1;
    }
}

.replace-asset-editor {
    .jstree-custom-style .jstree {
        max-height: 200px;
        min-height: 200px;
    }
    .text-block {
        position: absolute;
        right: 0;
    }
    .dropzone {
        flex: 0 0 auto;
        width: 65%;
        .dz-message {
            padding: 3rem 0;
        }
    }
    .dropzone-previews {
        max-height: 200px;
    }
    .timeline-item-info {
        &.block-1 {
            height: 35px;
        }
        &.block-2 {
            height: 75px;
        }
    }
    .bg-info-lighten {
        background-color: rgba(57, 175, 209, 0.25) !important;
    }
    .bg-success-lighten {
        background-color: rgba(10, 207, 151, 0.25) !important;
    }
    .upload-btn {
        position: absolute;
        margin-left: 30px;
        margin-top: -38px;
    }
}

.add-change-slide {
    img {
        width: 129px;
        height: 100%;
        cursor: pointer;
    }
}
//Styling for Permission based
.link-fund-right-table {
    .custom-height {
        height: 39px;
    }
}

//Select Cover image modal
.header-image-wrap {
    max-height: 650px;
    overflow-y: scroll;
    img {
        border: 4px solid $white;
        max-width: 195px;
        object-fit: cover;
        max-height: 150px;
        height: 150px;
        &:hover,
        &.selected {
            border: 4px solid #00afaa;
        }
    }
}

.print-preview-modal {
    iframe {
        display: block !important;
        background-color: transparent;
        height: 700px;
    }
}
