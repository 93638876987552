@mixin for-scroll {
    border-color: $gray-200;
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    overflow-y: auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.custom-listgroup {
    &.height-auto {
        height: auto;
    }
    height: 285px;
    max-height: 285px;
    @include for-scroll();
    .list-group-item {
        &:first-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-width: 0px;
        }
        &:nth-child(n + 2) {
            border-width: 1px 0px 0px 0px;
        }
        &:last-child {
            border-width: 1px 0px 1px 0px;
        }
        &.disabled {
            font-style: italic;
        }
    }
}
.custom-listgroup-variant {
    border-color: $gray-300;
    border-style: solid;
    border-width: 2px;
    height: 330px;
    max-height: 330px;
    overflow-y: auto;
    .list-group-item {
        &:first-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-width: 0px;
        }
        &:nth-child(n + 2) {
            border-width: 1px 0px 0px 0px;
        }
        &:last-child {
            border-width: 1px 0px 1px 0px;
        }
    }
}
.custom-listgroup-site-btd {
    height: 844px;
    max-height: 844px;
    @include for-scroll();
    .list-group-item {
        &:first-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-width: 0px;
        }
        &:nth-child(n + 2) {
            border-width: 1px 0px 0px 0px;
        }
        &:last-child {
            border-width: 1px 0px 0px 0px;
        }
    }
}
.custom-listgroup-report-btd {
    height: 1266px;
    max-height: 1266px;
    @include for-scroll();
    .list-group-item {
        &:first-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-width: 0px;
        }
        &:nth-child(n + 2) {
            border-width: 1px 0px 0px 0px;
        }
        &:last-child {
            border-width: 1px 0px 0px 0px;
        }
    }
}

.listheader {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    label {
        font-weight: 600;
    }
}

.custom-listgroup-impact-list {
    height: 360px;
    max-height: 360px;
    @include for-scroll();
    .list-group-item {
        &:first-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-width: 0px;
        }
        &:nth-child(n + 2) {
            border-width: 1px 0px 0px 0px;
        }
        &:last-child {
            border-width: 1px 0px 0px 0px;
        }
    }
}
// removing margin-left from buttonlist
.button-list-custom {
    .btn {
        margin-bottom: 12px;
    }
}

.custom-border {
    border: none;
    border-bottom: 1px solid #eff4f5;
}

.custom-list-header {
    white-space: nowrap;
}
//removing hand pointer none
.custom-hand-pointer-none {
    .list-group-item {
        cursor: default;
    }
}

.custom-listgroup-scroll {
    height: 250px;
    @include for-scroll();
    .list-group-item {
        height: 50px;
    }
}

.custom-height-fixed {
    height: 500px;
    @include for-scroll();
    border: none;
}
.custom-list-header-color {
    .list-group-item {
        background: $gray-200;
        border-color: $gray-200;
        color: #2b4049;
        font-weight: bold;
        small.underline {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
/*
 * Add selected class for Group setting Edit membership modal select all or none button
 */
.selected {
    text-decoration: underline;
}
// Add css for drag icon hide and alignment
.list-group-item {
    .visibility-hidden {
        visibility: hidden;
    }
}

.custom-list-group {
    .hstack.gap-2,
    .hstack.gap-1 {
        height: 30px;
    }
    .hover-primary:hover {
        color: $primary !important;
    }
    .hover-danger:hover {
        color: $danger !important;
    }
}

.list-group {
    .custom-border {
        background-color: #ffffff;
        position: sticky;
        top: 0;
        z-index: 100;
    }
}

.portfolio-create-site {
    .custom-tooltip-align {
        i {
            position: relative;
            top: 2px;
        }
    }
    .right-list {
        margin-top: 1px;
    }
}

.brands {
    .custom-listgroup {
        .custom-listgroup {
            border: none;
        }
    }
}
