#progress_circle_wrapper {
    #progress-circle {
        height: 84px;
        width: 84px;
        margin: auto;
        z-index: 1051;
        position: fixed;
        left: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        .progress-circle-img {
            width: 84px;
            height: 84px;
            background-color: rgba(255, 255, 255, 0.83);
            border-radius: 42px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            img {
                height: 84px;
                width: 84px;
            }
        }
    }
}
.changeCoverPicture {
    display: none;
}
.mce-content-body {
    [data-mce-selected='inline-boundary'] {
        background-color: transparent !important;
    }
}
.mce-content-body .mce-item-anchor {
    background-color: transparent !important;
}
ol,
ul {
    li {
        strong {
            font-weight: 700;
        }
    }
}

// REPORT UPDATE TAB CSS
// Report update tab hide on single tab & update off
.report-updates-tab.reportUpdateOff {
    display: none;
}
// vertical center alignment
.report-updates-tab .tab-wrapper li .report-update-name {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    &.ellipses {
        overflow: hidden;
        white-space: nowrap;
        word-wrap: break-word;
        hyphens: auto;
        display: block;
    }
}
.fund_list {
    cursor: pointer;
}
.panelDiv,
.newFooterLayout,
.addCarouselPanelOuter,
.slideDiv,
.notificationDiv,
.pb-hide {
    display: none !important;
}
.carousel-control {
    display: inherit !important;
}
