/****************************** MIXIN START ****************************/

/* Custom Filter style UI */
@mixin custom-filter-style($height, $width) {
    .custom-filter-style-block {
        height: $height;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: scroll;
        .custom-input {
            width: $width;
            display: inline;
        }
        .invalid-feedback {
            display: none !important;
        }
        .inner-wrapper {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: inline-flex;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-overflow-scrolling: touch;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
        }
        .list {
            border-radius: 2px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin: 0.75rem 0 0 0.75rem;
            min-width: 0;
            box-sizing: border-box;
            background-color: rgba(0, 176, 171, 0.2) !important;
            color: $primary !important;
            .value {
                border-radius: 2px;
                color: $primary;
                font-size: 100%;
                overflow: hidden;
                padding: 8px;
                padding-left: 11px;
                padding-right: 8px;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
            }
            .cross-icon {
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                border-radius: 2px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding: 0;
                padding-left: 8px;
                padding-right: 8px;
                box-sizing: border-box;
                &:hover {
                    background-color: rgba(0, 176, 171, 0.2) !important;
                    color: $primary !important;
                    cursor: pointer;
                }
                svg {
                    display: inline-block;
                    fill: currentColor;
                    line-height: 1;
                    stroke: currentColor;
                    stroke-width: 0;
                }
            }
        }
    }
}

/* fixed height & scroll */
@mixin custom-height-and-scroll($maxHeight) {
    max-height: $maxHeight;
    overflow-y: scroll;
}

/* Asset upload UI like asset uploader */
@mixin asset-upload($height) {
    .image-custom-upload-style {
        padding: 0 12px 0 50px;
        .custom-file {
            height: $height;
            width: 100%;
            input {
                height: inherit;
                width: inherit;
                border-radius: 0.25rem !important;
                border-style: dashed;
                text-align: center;
            }
            .input-group-text {
                position: absolute;
                right: 0;
                height: auto;
                width: auto;
                background-color: transparent;
                border: 0;
                &:hover {
                    color: $danger;
                }
            }
            .icon-block {
                position: absolute;
                width: 100%;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                i,
                h5 {
                    width: 100%;
                }
            }
        }
    }
}
//Shifter tables mixin --> linked fund, multiple sites/reports
@mixin shifter-tables(
    $card-height,
    $tbody-height,
    $no-pagination-height,
    $pagination-height,
    $pagination-tbody-height,
    $shifter-height
) {
    .card-body {
        height: $card-height;
    }
    .table-responsive {
        .shifter-table {
            tbody {
                height: $tbody-height;
            }
            &.shifter-right-table {
                tbody[role='rowgroup'] {
                    background-color: $gray-400;
                    tr {
                        background-color: $white;
                    }
                }
            }
        }
        &.height-fixed {
            height: $no-pagination-height;
            &.pagination-visible {
                height: $pagination-height !important;
                tbody {
                    height: $pagination-tbody-height;
                }
            }
        }
        tbody {
            border: 1px solid #eff4f5;
        }
    }

    .outer-wrapper {
        position: relative;
        height: $shifter-height;
        .inner-wrapper {
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            position: absolute;
        }
    }
}
//Custom header UI
@mixin custom-header {
    background-color: #eff4f5;
    color: #2c4149;
}
/****************************** MIXIN END ****************************/
//Css for disabled block
.disabled {
    option,
    select {
        opacity: 0.4;
        pointer-events: none;
    }
}
.page-disabled {
    option,
    select,
    p,
    span,
    input {
        opacity: 0.4;
        pointer-events: none;
    }
    label {
        opacity: 0.65;
    }
}
//Custom Specific class with specific value
.mt-10 {
    margin-top: 10px;
}
.h-inherit {
    height: inherit;
}
